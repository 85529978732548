import React, { useState, useEffect } from 'react'
import TopHeader from '../Components/TopHeader';
import Prince from '../Components/Prince';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useParams, useNavigate } from 'react-router-dom';

const LuckyDrawList = () => {
    const [users, setUsers] = useState([]);
   
    const initialFormData = {
        announcementTime: '',
    };
    const [formData, setFormData] = useState(initialFormData);

    const [filterByObj, setFilterByObj] = useState({
        search: '',
        projectId: '',
        schemeId: '',
        schemeType: '',
        unitNo: '',
        plc: '',
        size: '',
        allocatedTo: '',
        availability: '',
        registry: '',
        status: '',

    });

    const [idData, setIdData] = useState('');
    const [scame, setScame] = useState([]);
    const [project, setProject] = useState([]);
    const [displayStatus, setDisplayStatus] = useState([]);
    const [luckeyDraw, setLuckeyDraw] = useState('');
    const navigate = useNavigate()
    const navigation = useNavigate()
    const Token = localStorage.getItem("Token");
    const apiUrl = process.env.REACT_APP_URL;

    const loadcontent = (id, name, plan, lucky) => {
        navigation(`/create-plan/${id}/${name}/${plan}/${lucky}`);
    };

    const loadcontent2 = (id) => {
        navigation(`/plan-views/${id}`);
    };

    const loadcontent3 = (id) => {
        navigation(`/edit-lucky-draw/${id}`);
    };

    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    const formatTime2 = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);

        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        return date.toLocaleTimeString('en-IN', options);
    };


    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setFilterByObj(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    //project api 
    useEffect(() => {
        fetch(`${apiUrl}/project/applicantProjectDropdown`)
            .then((response) => response.json())
            .then((data) => {
                if (data.data && Array.isArray(data.data)) {
                    setProject(data.data);
                } else {
                    console.error('API response is not in the expected format for countries.');
                }

            })
            .catch((error) => {
                console.error('Error fetching country data:', error);
            });
    }, []);

    //schame
    useEffect(() => {
        const { schemeType, projectId } = formData;
        const url = `${apiUrl}/scheme/schemeDropdown`;
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.data && Array.isArray(data.data)) {
                    setScame(data.data);
                } else {
                    console.error('API response is not in the expected format for countries.');
                }

            })
            .catch((error) => {
                console.error('Error fetching country data:', error);
            });
    }, [formData]);


    // scame type
    useEffect(() => {
        const Token = localStorage.getItem('Token');

        fetch(`${apiUrl}/master/getAllMasterData/18`, {
            headers: {
                Authorization: `Bearer ${Token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    setDisplayStatus(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching gender options:', error);
            });
    }, []);


    // Handle input change for the specific user
    const handleInputChange = (e, id) => {
        const { name, value } = e.target;

        if (!isValidTime(value)) {
            toast.error("Please enter a valid time in 24-hour format (HH:mm).");
            return;
        }

        setFormData((prevData) => ({
            ...prevData,
            [id]: {
                ...prevData[id],
                [name]: value,
            },
        }));
    };


    const isValidTime = (time) => {
        const regex = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
        return regex.test(time);
    };


    const handleInputClick = (id) => {
        setIdData(id);
    };


    useEffect(() => {
        if (!idData || !formData[idData]?.announcementTime) return;

        const getEmpTime = async () => {
            const url = `${apiUrl}/luckyDraw/updateLuckyDrawTime?luckyDrawId=${idData}&announcementTime=${formData[idData].announcementTime}`;

            try {
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${Token}`,
                        "Content-Type": "application/json",
                    },
                });

                const result = await response.json();

                if (response.ok && result.status === "success") {
                    fetchDataFromApi()
                    // setFormData(initialFormData)
                    toast.success(result.message);
                } else {
                    toast.error(result.message || "Failed to update time.");
                }
            } catch (error) {
                toast.error("Failed to update lucky draw time. Please try again.");
                console.error("Error:", error);
            }
        };

        getEmpTime();
    }, [idData, formData, apiUrl, Token]);



    //list lucky draw 
    const fetchDataFromApi = async () => {
       
        const { search, schemeId ,projectId, schemeType,status} = filterByObj;
      
        try {
            const response = await fetch(`${apiUrl}/luckyDraw/luckDrawList?search=${search}&schemeId=${schemeId}&projectId=${projectId}&schemeType=${schemeType}&status=${status}`, {
                headers: {
                    'Authorization': `Bearer ${Token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            setLuckeyDraw(data.luckDrawCount)
            if (data.status === 'success') {
                if (Array.isArray(data.data)) {
                    const formattedData = data.data.map(item => ({
                        ...item,
                        startDate: item.startDate ? formatDateTime(item.startDate) : null,
                        startTime: item.startTime ? formatTime2(item.startTime) : null,

                        endDate: item.endDate ? formatDateTime(item.endDate) : null,
                        endTime: item.endTime ? formatTime2(item.endTime) : null,

                        announcementDate: item.announcementDate ? formatDateTime(item.announcementDate) : null,
                        announcementTime: item.announcementTime ? formatTime2(item.announcementTime) : null,

                        closeDate: item.closeDate ? formatDateTime(item.closeDate) : null,
                        closeTime: item.closeTime ? formatTime2(item.closeTime) : null,

                    }));
                    setUsers(formattedData);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            } else {
                console.error('API request was not successful:', data.message);
            }
           
        } catch (error) {
            console.error('Error fetching data:', error);
        }
       
    };


    useEffect(() => {
        fetchDataFromApi()
    }, [filterByObj]);



    useEffect(() => {
        const token = localStorage.getItem('Token');

        if (!token) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <>
            {/* Page */}
            <div className="page">

                <TopHeader />
                <Prince />

                <div className="main-content pt-0">
                    <div className="main-container container-fluid">
                        <div className="inner-body">
                            {/* Page Header */}
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5">Lucky Draw List({luckeyDraw})</h2>

                                </div>
                                <div className="d-flex">
                                    <div className="justify-content-center">
                                        <Link
                                            to='/add-lucky-draw'
                                            type="button"
                                            className="btn btn-primary my-2 btn-icon-text"
                                        >
                                            {" "}
                                            <i className="fe fe-plus me-2" /> Add Lucky Draw
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* End Page Header */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card custom-card">
                                        <div className="card-body py-3">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <input
                                                        type="search"
                                                        className="form-control form-control"
                                                        placeholder="Search by Luck Draw No, Name..."
                                                        aria-controls="example1"
                                                        name="search"
                                                        value={filterByObj.search}
                                                        onChange={handleInputChange2}
                                                    />
                                                </div>

                                                <div className="col-sm-4">
                                                    <select className="form-control select2"
                                                        name="schemeId"
                                                        value={filterByObj.schemeId}
                                                        onChange={handleInputChange2}
                                                    >
                                                        <option value=''>Select Scheme</option>
                                                        {scame.map((option, index) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.schemeName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>


                                                <div className="col-sm-4">
                                                    <select className="form-control select2"
                                                        name="projectId"
                                                        value={filterByObj.projectId}
                                                        onChange={handleInputChange2}
                                                    >
                                                        <option value=''>Select project</option>
                                                        {project.map((option, index) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.projectName}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </div>

                                                <div className="col-sm-4">
                                                    <select className="form-control select2"
                                                        name="schemeType"
                                                        value={filterByObj.schemeType}
                                                        onChange={handleInputChange2}
                                                        style={{ marginTop: '10px' }}
                                                    >
                                                        <option value=''>Select Type</option>
                                                        {displayStatus.map((option, index) => (
                                                            <option key={option.id} value={option.name}>
                                                                {option.name}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </div>


                                                <div className="col-sm-4">
                                                    <select className="form-control select2"
                                                        name="status"
                                                        value={filterByObj.status}
                                                        onChange={handleInputChange2}
                                                        style={{ marginTop: '10px' }}
                                                    >
                                                        <option value=''>Registration Status</option>
                                                        <option>Not Open</option>
                                                        <option>Open</option>
                                                        <option>Closed</option>


                                                    </select>
                                                </div>





                                            </div>
                                        </div>
                                    </div>
                                </div>{" "}
                            </div>
                            {/* Row */}
                            <div className="row row-sm">
                                <div className="col-lg-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="text-wrap">
                                                <div className="panel panel-primary tabs-style-3 p-0 tabs-style-3-0">
                                                    <div className="tab-content ">
                                                        <div className="tab-pane active" id="tab11">
                                                            <div className="table-responsive">

                                                                <table className="table table-striped table-bordered  mb-0">
                                                                    <thead >
                                                                        <tr>
                                                                            <th style={{ width: 20 }}>
                                                                                <label className="ckbox">
                                                                                    <input type="checkbox" defaultValue={5} />
                                                                                    <span />
                                                                                </label>
                                                                            </th>
                                                                            <th>ID </th>
                                                                            <th style={{ whiteSpace: 'nowrap' }}>Lucky Draw Name</th>
                                                                            <th style={{ whiteSpace: 'nowrap' }}>Scheme</th>
                                                                            <th style={{ whiteSpace: 'nowrap' }}>MAPPED PROJECT</th>
                                                                            <th style={{ whiteSpace: 'nowrap' }}>Plot</th>
                                                                            <th style={{ whiteSpace: 'nowrap' }}>Shop</th>
                                                                            <th style={{ whiteSpace: 'nowrap' }}>Farmhouse</th>
                                                                            <th style={{ whiteSpace: 'nowrap' }}>Fill Form Start Date/Time </th>
                                                                            <th style={{ whiteSpace: 'nowrap' }}>Fill Form End Date/Time</th>
                                                                            <th style={{ whiteSpace: 'nowrap' }}>Lucky Draw Open Date</th>
                                                                            <th style={{ whiteSpace: 'nowrap' }}>Lucky Draw Open Time</th>
                                                                            <th style={{ whiteSpace: 'nowrap' }}>Enable On App</th>
                                                                            <th>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody >
                                                                        {users.map((user) => (
                                                                            <tr>
                                                                                <td>
                                                                                    <label className="ckbox">
                                                                                        <input type="checkbox" defaultValue={5} />
                                                                                        <span />
                                                                                    </label>
                                                                                </td>
                                                                                <td>{user.id}</td>
                                                                                <td>
                                                                                    {user.luckyDrawName}

                                                                                </td>
                                                                                <td>{user.schemeId}</td>

                                                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                                                    {Array.isArray(user.luckyDrawProject) && user.luckyDrawProject.length > 0 ? (
                                                                                        user.luckyDrawProject.map((subscription, subIndex) => (
                                                                                            <div key={subIndex}>
                                                                                                {subscription?.project?.projectName}

                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <div>No Project available</div>
                                                                                    )}
                                                                                </td>

                                                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                                                    {Array.isArray(user.luckyDrawProject) && user.luckyDrawProject.length > 0 ? (
                                                                                        <>
                                                                                            {user.luckyDrawProject.map((subscription, subIndex) => (
                                                                                                <div key={subIndex}>
                                                                                                    {subscription?.project?.noOfPlot}
                                                                                                    <br />
                                                                                                    {subscription?.project?.noOfPlot > 0 && (
                                                                                                        <>
                                                                                                            {user?.plotPlan === false && (
                                                                                                                <>
                                                                                                                    <button
                                                                                                                        style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                                                                                                                        className="btn ripple btn-primary btn-xs w-70 equal-buttons"
                                                                                                                        onClick={() => loadcontent(user.scheme.id, subscription.project.id, 'Plot', user.id)}
                                                                                                                    >
                                                                                                                        <i className="fas fa-plus"></i>{" "} Plan
                                                                                                                    </button>
                                                                                                                </>
                                                                                                            )}
                                                                                                            {user?.plotPlan === true && (
                                                                                                                <>
                                                                                                                    <button
                                                                                                                        style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                                                                                                                        className="btn ripple btn-primary btn-xs w-70 equal-buttons"
                                                                                                                        onClick={() => loadcontent(user.scheme.id, subscription.project.id, 'Plot', user.id)}
                                                                                                                    >
                                                                                                                        <i className="fas fa-edit"></i>{" "} Plan
                                                                                                                    </button>
                                                                                                                </>
                                                                                                            )}
                                                                                                            <br />
                                                                                                            <br />
                                                                                                            {user?.plotPlan === true && (
                                                                                                                <>
                                                                                                                    <button
                                                                                                                        style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                                                                                                                        className="btn ripple btn-primary btn-xs w-70 equal-buttons"
                                                                                                                        onClick={() => loadcontent2(user.id)}
                                                                                                                    >
                                                                                                                        <i className="fas fa-eye"></i>{" "} Plan
                                                                                                                    </button>
                                                                                                                </>
                                                                                                            )}
                                                                                                            <br />
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <div>No Plot available</div>
                                                                                    )}
                                                                                </td>


                                                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                                                    {Array.isArray(user.luckyDrawProject) && user.luckyDrawProject.length > 0 ? (
                                                                                        user.luckyDrawProject.map((subscription, subIndex) => (
                                                                                            <div key={subIndex}>
                                                                                                {subscription?.project?.noOfShop}
                                                                                                <br />
                                                                                                {subscription?.project?.noOfShop > 0 && (
                                                                                                    <>

                                                                                                        {user?.shopPlan === false && (
                                                                                                            <>
                                                                                                                <button
                                                                                                                    style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                                                                                                                    className="btn ripple btn-primary btn-xs w-70 equal-buttons"
                                                                                                                    onClick={() => loadcontent(user.scheme.id, subscription.project.id, 'Shop', user.id)}
                                                                                                                >
                                                                                                                    <i className="fas fa-plus"></i> {" "}Plan
                                                                                                                </button>
                                                                                                            </>
                                                                                                        )}
                                                                                                        {user?.shopPlan === true && (
                                                                                                            <>
                                                                                                                <button
                                                                                                                    style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                                                                                                                    className="btn ripple btn-primary btn-xs w-70 equal-buttons"
                                                                                                                    onClick={() => loadcontent(user.scheme.id, subscription.project.id, 'Shop', user.id)}
                                                                                                                >
                                                                                                                    <i className="fas fa-edit"></i> {" "}Plan
                                                                                                                </button>
                                                                                                            </>
                                                                                                        )}

                                                                                                        <br />
                                                                                                        <br />
                                                                                                        {user?.shopPlan === true && (
                                                                                                            <>
                                                                                                                <button
                                                                                                                    style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                                                                                                                    className="btn ripple btn-primary btn-xs w-70 equal-buttons"
                                                                                                                    onClick={() => loadcontent2(user.id)}
                                                                                                                >
                                                                                                                    <i className="fas fa-eye"></i>{" "} Plan
                                                                                                                </button>
                                                                                                            </>
                                                                                                        )}
                                                                                                        <br />
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <div>No Shop available</div>
                                                                                    )}
                                                                                </td>



                                                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                                                    {Array.isArray(user.luckyDrawProject) && user.luckyDrawProject.length > 0 ? (
                                                                                        user.luckyDrawProject.map((subscription, subIndex) => (
                                                                                            <div key={subIndex}>
                                                                                                {subscription?.project?.noOfFarmHouse}
                                                                                                <br />
                                                                                                {subscription?.project?.noOfFarmHouse > 0 && (
                                                                                                    <>

                                                                                                        {user?.farmhousePlan === false && (
                                                                                                            <>
                                                                                                                <button
                                                                                                                    style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                                                                                                                    className="btn ripple btn-primary btn-xs w-70 equal-buttons"
                                                                                                                    onClick={() => loadcontent(user.scheme.id, subscription.project.id, 'FarmHouse', user.id)}
                                                                                                                >
                                                                                                                    <i className="fas fa-plus"></i>{" "} Plan
                                                                                                                </button>
                                                                                                            </>
                                                                                                        )}
                                                                                                        {user?.farmhousePlan === true && (
                                                                                                            <>
                                                                                                                <button
                                                                                                                    style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                                                                                                                    className="btn ripple btn-primary btn-xs w-70 equal-buttons"
                                                                                                                    onClick={() => loadcontent(user.scheme.id, subscription.project.id, 'FarmHouse', user.id)}
                                                                                                                >
                                                                                                                    <i className="fas fa-edit"></i>{" "} Plan
                                                                                                                </button>
                                                                                                            </>
                                                                                                        )}
                                                                                                        <br />
                                                                                                        <br />
                                                                                                        {user?.farmhousePlan === true && (
                                                                                                            <>
                                                                                                                <button
                                                                                                                    style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                                                                                                                    className="btn ripple btn-primary btn-xs w-70 equal-buttons"
                                                                                                                    onClick={() => loadcontent2(user.id)}
                                                                                                                >
                                                                                                                    <i className="fas fa-eye"></i>{" "} Plan
                                                                                                                </button>
                                                                                                            </>
                                                                                                        )}
                                                                                                        <br />
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <div>No FarmHouse available</div>
                                                                                    )}
                                                                                </td>


                                                                                <td>
                                                                                    {user.startDate || 'N/A'}
                                                                                    <br />
                                                                                    {user.startTime}
                                                                                    <br />
                                                                                    {user.formFillStatus === 'Registration Not Open' && (
                                                                                        <span style={{ color: 'blue', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>{user.formFillStatus}</span>
                                                                                    )}
                                                                                    {user.formFillStatus === 'Registration Open' && (
                                                                                        <span style={{ color: 'green', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>
                                                                                            {user.formFillStatus}
                                                                                        </span>
                                                                                    )}


                                                                                </td>

                                                                                <td>
                                                                                    {user.endDate || 'N/A'}
                                                                                    <br />
                                                                                    {user.endTime}
                                                                                    <br />
                                                                                    {user.formFillStatus === 'Registration Closed' && (
                                                                                        <span style={{ color: 'red', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>{user.formFillStatus}</span>
                                                                                    )}

                                                                                </td>

                                                                                <td> {user.announcementDate || 'N/A'} </td>

                                                                                <td>
                                                                                    {user.announcementTime || 'N/A'}
                                                                                    <br />
                                                                                    
                                                                                    {user.announcementTime && (
                                                                                        <input
                                                                                            type="time"
                                                                                            className="form-control"
                                                                                            placeholder="Select Announcement Time"
                                                                                            style={{ width: "200px" }}
                                                                                            name="announcementTime"
                                                                                            value={formData[user.id]?.announcementTime || ""}
                                                                                            onChange={(e) => handleInputChange(e, user.id)}
                                                                                            onClick={() => handleInputClick(user.id)}
                                                                                        />
                                                                                    )}


                                                                                </td>

                                                                                {user.enableOnMobile === true && (
                                                                                    <>

                                                                                        <td style={{ color: 'green' }}>Enable</td>
                                                                                    </>
                                                                                )}

                                                                                {user.enableOnMobile === false && (
                                                                                    <>

                                                                                        <td  style={{ color: 'red' }}>Disable</td>
                                                                                    </>
                                                                                )}
                                                                                <td>

                                                                                    <a onClick={() => loadcontent3(user.id)} >
                                                                                        <i className="fa fa-edit me-3" style={{ cursor: 'pointer' }} />
                                                                                    </a>
                                                                                    &nbsp;
                                                                                    <a >
                                                                                        <i className="fa fa-trash" style={{ cursor: 'pointer' }} />
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Row */}
                        </div>
                    </div>
                </div>
                {/* End Main Content*/}
                {/* Main Footer*/}
                <div className="main-footer text-center">
                    <div className="container">
                        <div className="row row-sm">
                            <div className="col-md-12">
                                <span>
                                    Copyright © 2024 <a href="javascript:void(0)">AMRS</a>. Designed
                                    by <a href="http://webkype.com/">Webkype.com</a> All rights
                                    reserved.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/*End Footer*/}
            </div>
        </>

    )
}

export default LuckyDrawList


