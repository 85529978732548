import React, { useState, useEffect } from 'react';
import TopHeader from '../Components/TopHeader';
import Prince from '../Components/Prince';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const UserApplicant = () => {

    const [users, setUsers] = useState([]);
    const [leadCount, setLeadCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filterByObj, setFilterByObj] = useState({
        search: '',
        from: '',
        to: '',
    });

    const navigate = useNavigate();
    const Token = localStorage.getItem("Token");
    const apiUrl = process.env.REACT_APP_URL;

    const handleChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        // Format the start and end dates if they are not null
        const formatDate = (date) => {
            if (date) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}/${month}/${day}`;
            }
            return '';
        };

        // Update the filterByObj state with the formatted date range
        const formattedStartDate = formatDate(start);
        const formattedEndDate = formatDate(end);


        setFilterByObj(prevState => ({
            ...prevState,
            from: `${formattedStartDate}`,
            to: `${formattedEndDate}`
        }));
    };


    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    const fetchDataFromApi = async () => {
        setLoading(true);
        const pageNumber = currentPage + 1;
        const { to, from, search } = filterByObj;
        const url = `${apiUrl}/user/getAllUser?page=${pageNumber}&limit=${itemsPerPage}&to=${to}&from=${from}&search=${search}`;

        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${Token}` }
            });

            const data = await response.json();

            if (data.status === 'success' && Array.isArray(data.data)) {
                const formattedData = data.data.map(item => ({
                    ...item,
                    createdAt: item.createdAt ? formatDateTime(item.createdAt) : '',
                }));
                setUsers(formattedData);
            } else {
                console.error('API response error:', data.message || 'Data is not an array');
            }
            const fetchedLeadCount = data.userCount;
            setLeadCount(fetchedLeadCount);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchDataFromApi();
    }, [currentPage, itemsPerPage, filterByObj]);


    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setFilterByObj(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const currentPageData = users;

    const handleItemsPerPageChange = (event) => {
        const newItemsPerPage = parseInt(event.target.value, 10);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(0);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentPage]);


    const keyframes = `
    @keyframes bounce {
      0%, 100% {
        transform: scale(0.9);
        opacity: 0.7;
      }
      50% {
        transform: scale(1.5);
        opacity: 1;
      }
    }
    `;

    const loaderStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.3)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
        },
        loaderContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
        },
        dot: {
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: '#3498db',
            animation: 'bounce 1.2s infinite ease-in-out',
        },
    };

    // Inject keyframes into the document
    const styleSheet = document.styleSheets[0];
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);


    useEffect(() => {
        const token = localStorage.getItem('Token');
        if (!token) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <>
            <div className="page">
                <TopHeader />
                <Prince />

                <div className="main-content pt-0">
                    <div className="main-container container-fluid">
                        <div className="inner-body">
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5">Applicants List ({leadCount})</h2>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link href="">All the User Sign up from App or Admin </Link>
                                        </li>
                                    </ol>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card custom-card">
                                        <div className="card-body py-3">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <input
                                                        type="search"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        aria-controls="example1"
                                                        name="search"
                                                        value={filterByObj.search}
                                                        onChange={handleInputChange2}
                                                    />
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="input-group">
                                                        <div className="input-group-text border-end-0">
                                                            <i className="fe fe-calendar lh--9 op-6" />
                                                        </div>
                                                        <div style={{ flex: '1' }}>
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={handleChange}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                selectsRange
                                                                placeholderText="Select Date Range"
                                                                dateFormat="dd/MM/yyyy"
                                                                className="form-control fc-datepicker"
                                                                style={{ height: '100%', width: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row row-sm">
                                <div className="col-lg-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                {loading ? (
                                                    <div style={loaderStyles.overlay}>
                                                        <div style={loaderStyles.loaderContainer}>
                                                            <div style={loaderStyles.dot}></div>
                                                            <div style={{ ...loaderStyles.dot, animationDelay: '0.2s' }}></div>
                                                            <div style={{ ...loaderStyles.dot, animationDelay: '0.4s' }}></div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                <table className="table table-striped table-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: 20 }}>
                                                                <label className="ckbox">
                                                                    <input type="checkbox" defaultValue={5} />
                                                                    <span />
                                                                </label>
                                                            </th>
                                                            <th>ID</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Created Date</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>User Image</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Name</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Email</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Mobile No</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>DOB</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Gender</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Married Status</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Password</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>State</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>City</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Address</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Status</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>User Type</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Total Applications</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Allocated</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Not Allocated</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentPageData.map((user) => (
                                                            <tr >
                                                                <td>
                                                                    <label className="ckbox">
                                                                        <input type="checkbox" defaultValue={5} />
                                                                        <span />
                                                                    </label>
                                                                </td>
                                                                <td>{user.id || 'N/A'}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{user.createdAt || 'N/A'}</td>
                                                                <td>
                                                                    <img
                                                                        alt="avatar"
                                                                        src={user.profilePhoto || 'https://cdn1.iconfinder.com/data/icons/website-internet/48/website_-_male_user-512.png'}
                                                                        style={{ width: 50 }}
                                                                    />
                                                                </td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{user.fullName || 'N/A'}</td>
                                                                <td>{user.email || 'N/A'}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{user.mobileNumber || 'N/A'}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{user.dob || 'N/A'}</td>
                                                                <td>{user.gender || 'N/A'}</td>
                                                                <td>{user.maritalStatus || 'N/A'}</td>
                                                                <td>{user.password || 'N/A'}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{user.state || 'N/A'}</td>
                                                                <td>{user.city || 'N/A'}</td>
                                                                <td >{user.address || 'N/A'}</td>

                                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                                    {user.status === 'Active' && (
                                                                        <span style={{ whiteSpace: 'nowrap', color: 'green' }}>{user.status || 'N/A'}</span>
                                                                    )}
                                                                    {user.status === 'In-Active' && (
                                                                        <span style={{ whiteSpace: 'nowrap', color: 'red' }}>{user.status || 'N/A'}</span>
                                                                    )}
                                                                </td>

                                                                <td>{user.userType}</td>
                                                                <td>{user.applicantCount}</td>
                                                                <td><Link to={`/applicant/${user.id}`}>{user.allocationCount }</Link></td>
                                                                <td><Link to={`/not-allocated/${user.id}`}>{user.notAllocationCount}</Link></td>

                                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                                    <button className="btn btn-sm btn-info me-2" >
                                                                        <span className="fe fe-edit"></span>
                                                                    </button>
                                                                    <button className="btn btn-sm btn-danger" >
                                                                        <span className="fe fe-trash-2"></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                )}
                                                <br />
                                                <div className="d-flex align-items-center ">
                                                    <div >

                                                        <select
                                                            id="itemsPerPage"
                                                            className="form-select"
                                                            value={itemsPerPage}
                                                            onChange={handleItemsPerPageChange}
                                                        >
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                            <option value={200}>200</option>
                                                            <option value={500}>500</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="pagination d-flex justify-content-center">
                                                    <ReactPaginate
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={Math.ceil(leadCount / itemsPerPage)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={'pagination'}
                                                        activeClassName={'active'}
                                                        previousLinkClassName={'page-link'}
                                                        nextLinkClassName={'page-link'}
                                                        disabledClassName={'disabled'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link'}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-footer text-center">
                    <div className="container">
                        <div className="row row-sm">
                            <div className="col-md-12">
                                <span>
                                    Copyright © 2024 <a href="javascript:void(0)">AMRS</a>. Designed
                                    by <a href="http://webkype.com/">Webkype.com</a> All rights
                                    reserved.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default UserApplicant


