import React from 'react'

const Footer = () => {
    return (
        <div className="main-footer text-center">
            <div className="container">
                <div className="row row-sm">
                    <div className="col-md-12">
                        <span>
                            Copyright © 2024 <a href="javascript:void(0)">AMRS</a>. Designed
                            by <a href="http://webkype.com/">Webkype.com</a> All rights
                            reserved.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer